let Images = {
  LOGO_IMG: require("../../Images/Gallery/logo.png"),
  SIDE_PANEL_IMG: require("../../Images/Gallery/Sidebar_Logo.png"),
  USER_DUMMY_IMG: require("../../Images/Gallery/User.png"),
  PetHome_Image: require("../../Images/Gallery/home.png"),
  Shear_Image: require("../../Images/Gallery/shear.png"),
  Order_Image: require("../../Images/Gallery/order.png"),
  Gift_Image: require("../../Images/Gallery/gift.png"),
  PAW_Image: require("../../Images/Gallery/pawicon.png"),

};

export default Images;
